import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Disqus } from "gatsby-plugin-disqus";
import Helmet from "react-helmet";
import { Link } from "gatsby";

import config from "../utils/siteConfig";

import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost;
    const author = post.primary_author;

    // Configure Disqus for this post
    let disqusConfig = {
        url: new URL(location.pathname, config.siteUrl).href,
        identifier: post.id,
        title: post.title,
    };

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        {post.feature_image ? (
                            <figure className="post-feature-image">
                                <img
                                    src={post.feature_image}
                                    alt={post.title}
                                />
                            </figure>
                        ) : null}
                        <section className="post-full-content">
                            <h1 className="content-title">{post.title}</h1>

                            <p className="post-excerpt">"{post.excerpt}"</p>

                            {/* Post Meta Data */}
                            <span className="post-meta-data">
                                By{" "}
                                <Link
                                    to={`/author/${post.primary_author.slug}`}
                                >
                                    {author.name}
                                </Link>{" "}
                                | Last Updated {post.updated_at_pretty}
                            </span>

                            <hr></hr>
                            {/* ---------------------------------------- */}

                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{
                                    __html: post.childHtmlRehype.html,
                                }}
                            />
                        </section>

                        {/* Post Footer */}
                        {/* Author Detail */}
                        <hr></hr>
                        <Link
                            to={`/author/${post.primary_author.slug}`}
                            className="no-hover-underline"
                        >
                            <header className="author-header">
                                <div className="author-header-content">
                                    <h1>Written by {author.name}</h1>
                                    {author.bio && <p>{author.bio}</p>}
                                </div>
                                <div className="author-header-image">
                                    {author.profile_image && (
                                        <img
                                            src={author.profile_image}
                                            alt={author.name}
                                        />
                                    )}
                                </div>
                            </header>
                        </Link>

                        <Disqus config={disqusConfig} />

                        <script
                            async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                        ></script>
                        <ins
                            className="adsbygoogle"
                            style={{ display: "block" }}
                            data-ad-client="ca-pub-2276670630750342"
                            data-ad-slot="4382659028"
                            data-ad-format="auto"
                            data-full-width-responsive="true"
                        ></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({});
                        </script>
                    </article>
                </div>
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            childHtmlRehype: PropTypes.shape({
                html: PropTypes.string.isRequired,
            }),
            feature_image: PropTypes.string,
            primary_author: PropTypes.shape({
                name: PropTypes.string.isRequired,
                profile_image: PropTypes.string,
                slug: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`;
